import React from "react";

const PersonaOverviewHelp: React.FunctionComponent = () => (
  <>
    <p>
      Use the All queries page to display all queries in the system grouped by
      CE number. Expand and collapse the CE number groups as needed. Use the
      search features at the top of the page to find a specific query.
    </p>
  </>
);

export default PersonaOverviewHelp;
