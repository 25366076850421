import React, { Dispatch, SetStateAction, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { syntheticsClient } from "../services/apollo";

export interface IAppContext {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  initialUrl: string;
  setInitialUrl: Dispatch<SetStateAction<IAppContext["initialUrl"]>>;
}
export const AppContext = React.createContext<IAppContext>(
  // This is definitional only. The provider creates the real values
  {} as IAppContext
);
AppContext.displayName = "AppContext";

interface Props {}
export const Provider = (({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [initialUrl, setInitialUrl] = useState("");

  const context: IAppContext = {
    isDrawerOpen,
    openDrawer: () => {
      setIsDrawerOpen(true);
    },
    closeDrawer: () => {
      setIsDrawerOpen(false);
    },
    initialUrl,
    setInitialUrl,
  };

  return (
    <ApolloProvider client={syntheticsClient}>
      <AppContext.Provider value={context}>{children}</AppContext.Provider>
    </ApolloProvider>
  );
}) as React.FunctionComponent<Props>;
