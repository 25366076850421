import { RouteProps } from "react-router-dom";

export interface AppRouteProps extends RouteProps {
  title: string;
  // A set of properties for making a mappable list
  path: string;
}

export const AppRouteHome: AppRouteProps = {
  title: `Dashboard`,
  path: `/`,
};

export const AppRouteLogin: AppRouteProps = {
  title: `Login`,
  path: `/:realm/login`,
};

export const AppRouteLogout: AppRouteProps = {
  title: `Logout`,
  path: `/logout`,
};

export const AppRoutePersonas: AppRouteProps = {
  title: `Query`,
  path: `/queries`,
};

export const AppRouteAllPersonas: AppRouteProps = {
  title: `All queries`,
  path: `/all-queries`,
};

export const AppRouteAdmin: AppRouteProps = {
  title: `Admin`,
  path: `/admin`,
};

export const AppRouteQueues: AppRouteProps = {
  title: `Queues`,
  path: `/queues`,
};

export const AppRouteAbout: AppRouteProps = {
  title: `About us`,
  path: `/about`,
};

export const AppRouteNotFound: AppRouteProps = {
  title: `Page not found`,
  path: `/`,
};
