import React, { useState } from "react";
import { Theme } from "@torch-ai-internal/react-display-components/lib/themes/createTheme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export interface IThemesContext {
  theme: Theme;
  themes: Theme[];
  setTheme: (theme: Theme) => void;
}

const ThemesContext = React.createContext<IThemesContext>({} as IThemesContext);
ThemesContext.displayName = "ThemesContext";
export default ThemesContext;

interface Props {
  themes: Theme[];
  defaultTheme?: Theme;
}
export const Provider = (({ themes, defaultTheme, children }) => {
  if (!themes.length) {
    throw new Error("At least one theme must be provided");
  }

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [theme, setTheme] = useState<Theme>(defaultTheme || themes[0]);
  const context: IThemesContext = {
    themes,
    theme,
    setTheme,
  };

  return (
    <ThemesContext.Provider value={context}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemesContext.Provider>
  );
}) as React.FunctionComponent<Props>;
