import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { AppRouteLogin } from "../AppRoutes";
import { User } from "../generated/torch-universe/security.types";
import { securityNamespace } from "../utils/permissions";

interface Context {
  user: Partial<User> | undefined;
  setUser: Dispatch<SetStateAction<Context["user"]>>;
  namespace: string;
  logout(): Promise<void>;
  token: string | undefined;
  setToken: Dispatch<SetStateAction<Context["token"]>>;
}

const UserContext = React.createContext<Context>({} as Context);
UserContext.displayName = "UserContext";
export default UserContext;

const sessionCachePath = "userContext";
export const sessionCacheUserPath = [sessionCachePath, "user"].join(".");
export const sessionCacheTokenPath = [sessionCachePath, "token"].join(".");

// Load anything we have in session for the user and token on boot
let sessionUser: User | undefined = undefined;
let sessionToken: string | undefined = undefined;
try {
  const sessionUserData = sessionStorage.getItem(sessionCacheUserPath);
  sessionUser = sessionUserData ? JSON.parse(sessionUserData) : undefined;
  const sessionTokenData = sessionStorage.getItem(sessionCacheTokenPath);
  sessionToken = sessionTokenData || "";
} catch (err) {
  console.error("Failed to hydrate user from sessionStorage", err);
}

interface Props {}
export const UserContextProvider = (({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<Context["user"]>(sessionUser);
  const [token, setToken] = useState<Context["token"]>(sessionToken);
  const [namespace, setNamespace] = useState<Context["namespace"]>("");

  // Sync user session storage so the Apollo client can get to it
  useEffect(() => {
    if (user) {
      sessionStorage.setItem(sessionCacheUserPath, JSON.stringify(user));
      // select the namespace on a role if it isn't nexus-security, this is the realm's main namespace
      setNamespace(
        user.roles?.find((role) => role.namespace !== securityNamespace)
          ?.namespace || ""
      );
    } else {
      sessionStorage.removeItem(sessionCacheUserPath);
      setNamespace("");
    }
  }, [user]);
  // Sync token into session storage so the Apollo client can get to it
  useEffect(() => {
    if (token) {
      sessionStorage.setItem(sessionCacheTokenPath, token);
    } else {
      sessionStorage.removeItem(sessionCacheTokenPath);
    }
  }, [token]);

  const routeToLogin = useCallback(() => {
    console.info("Routing user to login");
    if (!AppRouteLogin.path) {
      throw new Error("AppRouteLogin.path is undefined");
    }
    navigate(AppRouteLogin.path.replace(":realm", user?.realm || ""), {
      replace: true,
    });
  }, [navigate, user?.realm]);

  const logout: Context["logout"] = async () => {
    setUser(undefined);
    setToken(undefined);
    setNamespace("");
    routeToLogin();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        namespace,
        logout,
        token,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}) as React.FunctionComponent<Props>;
