import { AppRouteAdmin, AppRouteProps } from "../../AppRoutes";

export const AppRouteResetPassword: AppRouteProps = {
  title: `Reset password`,
  path: `${AppRouteAdmin.path}/reset-password`,
};

export const AdminRouteLicenses: AppRouteProps = {
  title: `License details`,
  path: `${AppRouteAdmin.path}/license-details`,
};

export const AdminRouteClusters: AppRouteProps = {
  title: "Task orders",
  path: `${AppRouteAdmin.path}/task-orders`,
};

export const AdminRouteClusterGroups: AppRouteProps = {
  title: "CE IDs",
  path: `${AppRouteAdmin.path}/ce-ids`,
};
export const AdminRouteEditUser: AppRouteProps = {
  title: "Edit user",
  path: `${AppRouteAdmin.path}/edit-user/:id`,
};

export const AdminRouteCreateUser: AppRouteProps = {
  title: "Create user",
  path: `${AppRouteAdmin.path}/create`,
};

export const AdminRouteServices: AppRouteProps = {
  title: `Services`,
  path: `${AppRouteAdmin.path}/services-catalog`,
};

export const AdminRouteUserManagement: AppRouteProps = {
  title: `User management`,
  path: `${AppRouteAdmin.path}/user-management`,
};
