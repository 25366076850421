import { AdminRole } from "../../../../generated/torch-universe/admin.types";
import { UserType } from "../../adminUtils";
import {
  Role,
  User,
} from "../../../../generated/torch-universe/security.types";

export const isJrUser = (roles: AdminRole[] | Role[] | [] | undefined) =>
  roles?.some((role) => getRoleString(role) === UserType.JrOperator) || false;
export const isUser = (roles: AdminRole[] | Role[] | [] | undefined) =>
  roles?.some((role) => getRoleString(role) === UserType.Operator) || false;
export const isAdmin = (roles: AdminRole[] | Role[] | [] | undefined) =>
  roles?.some((role) => getRoleString(role) === UserType.Admin) || false;
export const isSuperAdmin = (roles: AdminRole[] | Role[] | [] | undefined) =>
  roles?.some((role) => getRoleString(role) === UserType.SuperAdmin) || false;
export const isUserAdmin = (roles: AdminRole[] | Role[] | [] | undefined) =>
  roles?.some((role) => getRoleString(role) === UserType.UserAdmin) || false;

// @ts-expect-error doesn't like that roles and AdminRoles are different
const getRoleString = (role: Role | AdminRole) => role?.name || role?.authority;

const getRolesObj = (roles: AdminRole[] | Role[] | undefined) => ({
  isJrUser: isJrUser(roles),
  isUser: isUser(roles),
  isAdmin: isAdmin(roles),
  isSuperAdmin: isSuperAdmin(roles),
});

export const canManageThisPersona = (
  user: Partial<User> | undefined,
  personaCreatedBy: string | undefined | null
) => {
  const { isAdmin, isSuperAdmin, isJrUser, isUser } = getRolesObj(user?.roles);
  return (
    isAdmin ||
    isSuperAdmin ||
    ((isJrUser || isUser) && personaCreatedBy === user?.username)
  );
};

export const canApprovePersonaPosts = (
  user: Partial<User> | undefined,
  personaCreatedBy: string | undefined | null
) => canManageThisPersona(user, personaCreatedBy) && !isJrUser(user?.roles);

export const canEditSuperAdmin = (
  securityContextRoles: AdminRole[] | undefined,
  userRoles: Role[] | undefined
) =>
  isSuperAdmin(userRoles) ||
  (isUserAdmin(userRoles) && !isSuperAdmin(securityContextRoles));
