import React from "react";

const PersonaOverviewHelp: React.FunctionComponent = () => (
  <>
    <p>
      The query overview page is a centralized location for managing a query. It
      displays details about a query and provides access to the following tasks:
    </p>
    <ul>
      <li>Create a post Review and approve posts</li>
      <li>Manage social media integration settings</li>
      <li>View all information associated with the query</li>
      <li>
        Deactivate the query after it has reached the end of its useful life.
        Deactivation does not remove the query from this system or from the
        social media platform, nor does it affect the number of licenses
        available.
      </li>
      <li>Delete the query</li>
    </ul>
  </>
);

export default PersonaOverviewHelp;
