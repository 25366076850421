import React, { useContext, useEffect, useState } from "react";
import {
  ClusterGroup,
  GetAllClusterGroupsLazyQueryHookResult,
  GetClusterGroupsForAdminUserLazyQueryHookResult,
  GetClusterGroupsForUserLazyQueryHookResult,
  GetClustersForAdminUserLazyQueryHookResult,
  GetClustersLazyQueryHookResult,
  useGetAllClusterGroupsLazyQuery,
  useGetClusterGroupsForAdminUserLazyQuery,
  useGetClusterGroupsForUserLazyQuery,
  useGetClustersForAdminUserLazyQuery,
  useGetClustersLazyQuery,
  useIsPermittedQuery,
} from "../generated/torch-universe/synthetics.types";
import { PermissionTargets, PermissionVerbs } from "../utils/permissions";
import UserContext from "./UserContext";
import {
  isAdmin,
  isJrUser,
  isSuperAdmin,
  isUser,
} from "../components/Admin/components/UserManagement/utils";

export interface IGroupsContext {
  getAllClusterGroups: GetAllClusterGroupsLazyQueryHookResult[0];
  getAllClusterGroupsQuery: GetAllClusterGroupsLazyQueryHookResult[1];
  getClusterGroupsForUserQuery: GetClusterGroupsForUserLazyQueryHookResult[1];
  getClustersForAdminUserQuery: GetClustersForAdminUserLazyQueryHookResult[1];
  getClusterGroupsForAdminUserQuery: GetClusterGroupsForAdminUserLazyQueryHookResult[1];
  getClustersQuery: GetClustersLazyQueryHookResult[1];
  isReadClustersPermitted: boolean;
  authorizedClusterGroups: ClusterGroup[];
}
export const GroupsContext = React.createContext<IGroupsContext>(
  // This is definitional only. The provider creates the real values
  {} as IGroupsContext
);
GroupsContext.displayName = "GroupsContext";

interface Props {}
export const Provider = (({ children }) => {
  const [getAllClusterGroups, getAllClusterGroupsQuery] =
    useGetAllClusterGroupsLazyQuery();
  const [getClusterGroupsForUser, getClusterGroupsForUserQuery] =
    useGetClusterGroupsForUserLazyQuery();

  const [getClustersForAdminUser, getClustersForAdminUserQuery] =
    useGetClustersForAdminUserLazyQuery();

  const [getClusterGroupsForAdminUser, getClusterGroupsForAdminUserQuery] =
    useGetClusterGroupsForAdminUserLazyQuery();

  const [authorizedClusterGroups, setAuthorizedClusterGroups] = useState<
    ClusterGroup[]
  >([]);

  useEffect(() => {
    setAuthorizedClusterGroups(
      getAllClusterGroupsQuery.data?.getAllClusterGroups ||
        getClusterGroupsForAdminUserQuery.data?.getClusterGroupsForAdminUser ||
        getClusterGroupsForUserQuery.data?.getClusterGroupsForUser ||
        []
    );
  }, [
    getAllClusterGroupsQuery.data?.getAllClusterGroups,
    getClusterGroupsForAdminUserQuery.data?.getClusterGroupsForAdminUser,
    getClusterGroupsForUserQuery.data?.getClusterGroupsForUser,
  ]);

  const { namespace, user } = useContext(UserContext);
  const isReadClusterGroupsPermitted = useIsPermittedQuery({
    variables: {
      target: PermissionTargets.clusterGroup,
      verb: PermissionVerbs.read,
      namespace,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (isReadClusterGroupsPermitted.data?.isPermitted) {
      if (isSuperAdmin(user?.roles)) {
        getAllClusterGroups();
      } else if (isAdmin(user?.roles)) {
        getClusterGroupsForAdminUser();
      } else if (isUser(user?.roles) || isJrUser(user?.roles)) {
        getClusterGroupsForUser({ variables: { userId: user?.id || "" } });
      }
    }
  }, [
    isReadClusterGroupsPermitted.data?.isPermitted,
    getAllClusterGroups,
    getClusterGroupsForUser,
    getClusterGroupsForAdminUser,
    user?.id,
    user?.roles,
  ]);

  const isReadClustersPermitted = useIsPermittedQuery({
    variables: {
      target: PermissionTargets.cluster,
      verb: PermissionVerbs.read,
      namespace,
    },
    fetchPolicy: "network-only",
  });

  const [getClusters, getClustersQuery] = useGetClustersLazyQuery();
  useEffect(() => {
    if (
      isReadClustersPermitted.data?.isPermitted &&
      isSuperAdmin(user?.roles)
    ) {
      getClusters();
    } else if (
      isReadClustersPermitted.data?.isPermitted &&
      isAdmin(user?.roles)
    ) {
      getClustersForAdminUser();
    }
  }, [
    isReadClustersPermitted.data?.isPermitted,
    getClusters,
    getClustersForAdminUser,
    user?.roles,
  ]);

  const context: IGroupsContext = {
    getAllClusterGroupsQuery,
    getAllClusterGroups,
    getClusterGroupsForUserQuery,
    getClusterGroupsForAdminUserQuery,
    getClustersQuery,
    getClustersForAdminUserQuery,
    authorizedClusterGroups,
    isReadClustersPermitted: isReadClustersPermitted.data?.isPermitted
      ? true
      : false,
  };

  return (
    <GroupsContext.Provider value={context}>{children}</GroupsContext.Provider>
  );
}) as React.FunctionComponent<Props>;
