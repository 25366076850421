import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "tss-react/mui";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  AppRouteAdmin,
  AppRouteAllPersonas,
  AppRouteHome,
  AppRoutePersonas,
} from "../../AppRoutes";
import DashboardHelp from "./components/DashboardHelp";
import { useLocation } from "react-router-dom";
import PersonaOverviewHelp from "./components/PersonaOverviewHelp";
import PersonaContentHelp from "./components/PersonaContentHelp";
import PersonaServiceIntegrationsHelp from "./components/PersonaServiceIntegrationsHelp";
import PersonaReviewHelp from "./components/PersonaReviewHelp";
import PersonaSearchHelp from "./components/PersonaSearchHelp";
import AdminHelp from "./components/AdminHelp";
import PersonaCreateHelp from "./components/PersonaCreateHelp";
import AllPersonasHelp from "./components/AllPersonasHelp";
import SecondaryButton from "@torch-ai-internal/react-display-components/lib/components/Forms/Inputs/SecondaryButton/SecondaryButton";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<Props["isOpen"]>>;
}
const Help: React.FunctionComponent<Props> = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const { classes } = useStyles();

  const isPersonaViewRoute = (
    personaSubRoute: string,
    personaIdOrPage: number | string
  ) => personaSubRoute === "" && Boolean(Number(personaIdOrPage));

  // location.pathname = "/queries/2600312/review"
  const personaPath = location.pathname.slice(0, 8);
  const personaSubRoute = location.pathname.slice(17).split("/")[0];
  const personaIdOrPage = location.pathname.slice(9, 16);

  const isPersonaRoute = (route: number | string) => {
    if (personaPath === AppRoutePersonas.path) {
      if (route === personaSubRoute || route === personaIdOrPage) {
        return true;
      }
    }
    return false;
  };

  const getHelp = () => {
    if (location.pathname === AppRouteHome.path) {
      return ["Dashboard", <DashboardHelp />];
    } else if (location.pathname === AppRouteAdmin.path) {
      return ["Admin", <AdminHelp />];
    } else if (location.pathname === AppRouteAllPersonas.path) {
      return ["All queries", <AllPersonasHelp />];
    } else if (isPersonaRoute("content")) {
      return ["Posts", <PersonaContentHelp />];
    } else if (isPersonaRoute("service-integrations")) {
      return ["Service integrations", <PersonaServiceIntegrationsHelp />];
    } else if (isPersonaRoute("review")) {
      return ["Review", <PersonaReviewHelp />];
    } else if (isPersonaRoute("search")) {
      return ["Search", <PersonaSearchHelp />];
    } else if (isPersonaRoute("create")) {
      return ["Create", <PersonaCreateHelp />];
    } else if (isPersonaViewRoute(personaSubRoute, personaIdOrPage)) {
      return ["Overview", <PersonaOverviewHelp />];
    } else {
      return [
        "No help available",
        <>No help is currently available for this page</>,
      ];
    }
  };
  const [title, content] = getHelp();

  return (
    <Dialog open={isOpen} maxWidth={"md"} onClose={() => setIsOpen(false)}>
      <DialogTitle>
        <div className={classes.dialogTitleItems}>
          <div className={classes.title}>{title}</div>
          <SecondaryButton
            onClick={() => setIsOpen(false)}
            variant="outlined"
            color="secondary"
          >
            Close
          </SecondaryButton>
        </div>
      </DialogTitle>

      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};
export default Help;

const useStyles = makeStyles()((theme) => ({
  dialogTitleItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    paddingTop: theme.spacing(0.625),
  },
}));
