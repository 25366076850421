import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ThemesContextProvider } from "../../../contexts/ThemesContext";
import { Provider as LicenseContextProvider } from "../../../contexts/LicenseContext";
import { Provider as AppContextProvider } from "../../../contexts/AppContext";
import { Provider as SnackbarContextProvider } from "../../../contexts/SnackbarContext";
import { BrowserRouter as Router } from "react-router-dom";
import { UserContextProvider } from "../../../contexts/UserContext";
import { syntheticsClient } from "../../../services/apollo";
import { ApolloProvider } from "@apollo/client";
import torchOrangeLight from "@torch-ai-internal/react-display-components/lib/themes/torchOrangeLight";

const Providers: React.FunctionComponent = ({ children }) => {
  return (
    <Router>
      {/*This needs to be kept inside <Router /> to control redirection for authentication*/}
      <AppContextProvider>
        <ThemesContextProvider themes={[torchOrangeLight]}>
          <UserContextProvider>
            <ApolloProvider client={syntheticsClient}>
              <LicenseContextProvider>
                <SnackbarContextProvider>
                  <HelmetProvider>{children}</HelmetProvider>
                </SnackbarContextProvider>
              </LicenseContextProvider>
            </ApolloProvider>
          </UserContextProvider>
        </ThemesContextProvider>
      </AppContextProvider>
    </Router>
  );
};
export default Providers;
