import React from "react";

const PersonaContentHelp: React.FunctionComponent = () => (
  <>
    <p>
      The Posts page enables you to create a new social media post for the
      persona. You can use an AI-enabled option to auto-generate a post. Note
      that you must have configured social media integration details before you
      can create a post.
    </p>
    <p>To create a post:</p>
    <ol>
      <li>
        Under Create post, click the <b>Service integration</b> menu and select
        the desired social media platform. The platform settings are configured
        on the Social media integrations page.
      </li>
      <li>
        To auto-generate a post using AI, click <b>AI Generate</b>. The
        AI-generated post displays in the <b>Recent content</b> list, ready for
        review and approval. <b>Note:</b>If you do not wish to use the
        AI-generated post, click <b>Reject</b>.
      </li>
      <li>
        To manually enter a post, enter it in the <b>Content</b> field. When
        finished, click the <b>Publish</b> menu and click <b>Publish</b>. The
        new post displays in the <b>Recent Content</b> list, ready for review
        and approval. <b>Note:</b>If you do not wish to use a manually entered
        post, click <b>Delete</b>.
      </li>
      <li>
        The post will not be published on the social media site until it is
        reviewed and approved. To approve a post for publication, click{" "}
        <b>Review</b>.
      </li>
    </ol>
  </>
);

export default PersonaContentHelp;
