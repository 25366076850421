import React, { useEffect } from "react";
import {
  GetPersonaLazyQueryHookResult,
  PersonaServiceConnectionLazyQueryHookResult,
  PostLifecycleState,
  PostSortBy,
  SearchPostsLazyQueryHookResult,
  useGetPersonaLazyQuery,
  usePersonaServiceConnectionLazyQuery,
  useSearchPostsLazyQuery,
} from "../../generated/torch-universe/synthetics.types";
import { useParams } from "react-router-dom";
import { isPersonaDeactivated } from "./personaUtils";
import { isExpired } from "../../utils/date";

interface Context {
  id: string;
  personaQuery: GetPersonaLazyQueryHookResult[1];
  serviceConnectionsQuery: PersonaServiceConnectionLazyQueryHookResult[1];
  postsQuery: SearchPostsLazyQueryHookResult[1];
  isDisabled: boolean;
  isDeactivated: boolean;
}
const PersonaContext = React.createContext<Context>({} as Context);
PersonaContext.displayName = "PersonaContext";
export default PersonaContext;
export const PersonaContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const params = useParams();
  const id = params.id || "";
  const [getPersona, personaQuery] = useGetPersonaLazyQuery();
  const [getPersonaConnections, serviceConnectionsQuery] =
    usePersonaServiceConnectionLazyQuery();
  const [getPosts, postsQuery] = useSearchPostsLazyQuery();

  useEffect(() => {
    getPersona({
      variables: {
        id: id,
      },
    });
  }, [id, getPersona]);

  useEffect(() => {
    getPersonaConnections({
      variables: {
        id: id,
      },
    });
  }, [id, getPersonaConnections]);

  useEffect(() => {
    getPosts({
      variables: {
        filter: {
          personId: Number(id),
          sortBy: PostSortBy.LastActivity,
          states: [
            PostLifecycleState.Generated,
            PostLifecycleState.Accepted,
            PostLifecycleState.Posted,
          ],
        },
      },
    });
  }, [getPosts, id]);

  const isDisabled =
    isPersonaDeactivated(personaQuery.data?.getPersona?.personLifecycleState) ||
    isExpired(personaQuery.data?.getPersona?.expirationDate);
  const isDeactivated = isPersonaDeactivated(
    personaQuery.data?.getPersona?.personLifecycleState
  );

  return (
    <PersonaContext.Provider
      value={{
        id,
        personaQuery,
        serviceConnectionsQuery,
        postsQuery,
        isDisabled,
        isDeactivated,
      }}
    >
      {children}
    </PersonaContext.Provider>
  );
};
