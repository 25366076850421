import { makeStyles } from "tss-react/mui";

// EXAMPLE: conditional styles in class
export const useUtilityStyles = makeStyles({ name: "UtilityStyles" })(
  (theme) => ({
    height100: {
      height: "100%",
    },
    displayFlexLeft: {
      display: "flex",
      justifyContent: "left",
    },
    displayFlexCenter: {
      display: "flex",
      justifyContent: "center",
    },
    flexGrow: {
      flexGrow: 1,
    },
    width100: {
      width: "100%",
    },
    secondaryColorContrastText: {
      color: theme.palette.secondary.contrastText,
    },
    textAlignCenter: {
      textAlign: "center",
    },
    padding1: {
      padding: theme.spacing(1),
    },
    paddingMd: {
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    marginVertical1: {
      margin: theme.spacing(1, 0),
    },
    margin1: {
      margin: theme.spacing(1),
    },
    marginInputLabel: {
      margin: theme.spacing(1.5, 0, -0.5),
    },
    displayNoneLg: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    displayNoneMd: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    displayNoneSm: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    displayBlock: {
      display: "block",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    disabledText: {
      color: theme.palette.text.disabled,
    },
  })
);
