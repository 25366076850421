import React from "react";
import rolesPermissions from "../../../assets/roles-permissions.png";
import { Box, Typography } from "@mui/material";

const PersonaOverviewHelp: React.FunctionComponent = () => (
  <>
    <p>The Admin page enables you to adjust certain configuration settings.</p>
    <br />
    <Typography component="h4">Task orders</Typography>
    <p>
      Task orders are the top-most grouping of CE numbers, representing a group
      of groups of queries. The Task order is the first three digits of the CE
      number. The CE number is a unique identifier for a query. Its primary
      purpose is to link a query with external data without revealing the
      details of the query, thus preventing the oversharing of information.
    </p>
    <p>
      To create a Task order, your user account must be assigned the
      SuperAdministrator role. Click Create and enter the Task order in the
      fields provided.
    </p>
    <br />
    <Typography component="h4">CE IDs</Typography>
    <p>
      The CE number is a unique identifier for a query. Its primary purpose is
      to link a query with external data without revealing the details of the
      query, thus preventing the oversharing of information.
    </p>
    <p>An example CE is:</p>
    <p>100-CE-001</p>
    <p>This number contains for components that uniquely identify a query:</p>
    <ul>
      <li>
        <b>Task Order Number</b>. The first three integers, which can be 001
        thru 999.
      </li>
      <li>
        <b>CE</b>. The literal characters "CE" that identifies to external
        systems that the number is a CE number.
      </li>
      <li>
        <b>CE ID</b>. The following three integers, which can be 001 thru 999,
        followed by a period.
      </li>
    </ul>
    <br />
    <Typography>Licenses</Typography>
    <p>
      Displays license details including the total number of queries purchased,
      created, and available for use. This section also displays details on the
      individual licenses purchased, as well as the inactive licenses.
    </p>
    <br />
    <Typography component="h4">User management</Typography>
    <p>
      Enables you to manage user accounts in Nexus Q. To use this feature, your
      user account must be assigned the UserAdministrator or SuperAdministrator
      roles.
    </p>
    <p>
      The following table summarizes the permissions associated with each role.
    </p>
    <Box
      component="img"
      src={rolesPermissions}
      alt="A table of user roles and the permissions associated with them"
      sx={{ width: "100%" }}
    />
    <br />
    <Typography component="h4">Reset password</Typography>
    <p>
      Enables you to reset the password associated with your user account. To
      reset your password, enter the old and new passwords in the fields
      provided and click <b>Reset Password</b>.
    </p>
    <br />
    <Typography component="h5">Utilities</Typography>
    <p>
      <b>Synthetics operational logs</b>. Nexus Q provides the capability to log
      the activities output from the Docker containers hosting the
      microservices. You can store these logs outside of the Nexus Q
      environment. The Nexus Q application provides a link to the external data
      source. Note the following prerequisites to this feature:
    </p>
    <p>
      Configure an external data store to capture the output of the Docker
      containers.
    </p>
    <p>
      Set the environment variable that defines the "Nexus Q synthetic
      operational logs" link.
    </p>
  </>
);

export default PersonaOverviewHelp;
