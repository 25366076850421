import React from "react";

const PersonaOverviewHelp: React.FunctionComponent = () => (
  <>
    <p>
      After you create a new post, it must be approved by another user before it
      will be published to the actual platform.
    </p>
    <p>To review and approve new social media posts for publication: </p>
    <ol>
      <li>
        Before you approve a post, you may want to edit the content. To edit a
        post, click <b>Edit</b>, make your changes, and then click <b>Update</b>
        .
      </li>
      <li>
        To approve a post, select <b>Approve</b>. This option is unavailable if
        you are the creator of the post. When a post is approved, it is sent to
        the automated queue for immediate posting to the social media platform.
      </li>
      <li>
        If a post does not meet your specifications, you may reject or delete
        it. Select one of the following options:{" "}
        <ul>
          <li>
            If the post was AI-generated, click <b>Reject</b>.{" "}
          </li>
          <li>
            If the post was manually entered, click <b>Delete</b>.
          </li>
        </ul>
      </li>
      <li>
        To return to the query's Overview page, click the query's name or
        profile picture in the header.
      </li>
    </ol>
  </>
);

export default PersonaOverviewHelp;
