import React, { Suspense, useContext } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import {
  AppRouteAbout,
  AppRouteAdmin,
  AppRouteAllPersonas,
  AppRouteHome,
  AppRouteLogin,
  AppRouteLogout,
} from "../../../AppRoutes";
import { Provider as GroupsContextProvider } from "../../../contexts/GroupsContext";
import PageLoading from "@torch-ai-internal/react-display-components/lib/components/Placeholders/PageLoading/PageLoading";
import AboutUs from "@torch-ai-internal/react-display-components/lib/components/Content/AboutUs/AboutUs";
import UserContext from "../../../contexts/UserContext";
import { PersonaContextProvider } from "../../Persona/PersonaContext";
import {
  PersonaContentRoute,
  PersonaCreateRoute,
  PersonaEditRoute,
  PersonaLifeEventCreateEditRoute,
  PersonaLifeEventsRoute,
  PersonaLogsRoute,
  PersonaReviewRoute,
  PersonaSearchRoute,
  PersonaServiceIntegrationsCreateEditRoute,
  PersonaServiceIntegrationsLogsRoute,
  PersonaServiceIntegrationsRoute,
  PersonaViewDetailRoute,
  PersonaViewRoute,
} from "../../Persona/Routes";
import AdminRouter from "../../Admin/AdminRouter";
import {
  AdminRouteClusterGroups,
  AdminRouteClusters,
  AdminRouteCreateUser,
  AdminRouteEditUser,
  AdminRouteLicenses,
  AdminRouteServices,
  AdminRouteUserManagement,
  AppRouteResetPassword,
} from "../../Admin/AdminRoutes";

const Home = React.lazy(() => import("../../Home/Home"));
const Login = React.lazy(() => import("../../Login/Login"));
const ResetPassword = React.lazy(
  () => import("../../Admin/components/ResetPassword/Page")
);
const Logout = React.lazy(() => import("../../Logout/Logout"));
const AllPersonas = React.lazy(() => import("../../AllPersonas/AllPersonas"));
const NotFound404 = React.lazy(() => import("../../NotFound404/NotFound404"));

const Admin = React.lazy(() => import("../../Admin/Admin"));

const LicenseDetails = React.lazy(
  () => import("../../Admin/components/LicenseDetails")
);
const ServicesCatalog = React.lazy(
  () => import("../../Admin/components/ServicesCatalog/ServicesCatalog")
);
const CreateUser = React.lazy(
  () =>
    import("../../Admin/components/UserManagement/components/CreateUser/Page")
);
const UserManagement = React.lazy(
  () => import("../../Admin/components/UserManagement/Page")
);
const UserEdit = React.lazy(
  () => import("../../Admin/components/UserEdit/Page")
);
const Clusters = React.lazy(
  () => import("../../Admin/components/Clusters/Clusters")
);
const ClusterGroups = React.lazy(
  () => import("../../Admin/components/ClusterGroups/ClusterGroups")
);

const Create = React.lazy(() => import("../../Persona/components/Create/Page"));
const View = React.lazy(() => import("../../Persona/components/View/Page"));
const ViewDetail = React.lazy(
  () => import("../../Persona/components/ViewDetail/Page")
);
const Edit = React.lazy(() => import("../../Persona/components/Edit/Page"));
const Qa = React.lazy(() => import("../../Persona/components/Review/Page"));
const Search = React.lazy(() => import("../../Persona/components/Search/Page"));
const LifeEvents = React.lazy(
  () => import("../../Persona/components/LifeEvents/Page")
);
const LifeEventEdit = React.lazy(
  () => import("../../Persona/components/LifeEvents/components/Edit/Page")
);
const Posts = React.lazy(() => import("../../Persona/components/Posts/Page"));
const ServiceConnections = React.lazy(
  () => import("../../Persona/components/ServiceConnections/Page")
);
const ServiceConnectionsLogs = React.lazy(
  () => import("../../Persona/components/ServiceConnections/Logs/Page")
);
const PersonaLogs = React.lazy(
  () => import("../../Persona/components/Logs/Page")
);
const CreateEditConnections = React.lazy(
  () =>
    import("../../Persona/components/ServiceConnections/components/Edit/Page")
);

export default (() => {
  const { user } = useContext(UserContext);
  const isLoggedIn = user?.id && user?.username;
  const initialLocation = useLocation();
  if (
    !isLoggedIn &&
    !sessionStorage.getItem("initialLocation") &&
    !initialLocation.pathname.includes("/login") &&
    !initialLocation.pathname.includes("/reset-password")
  ) {
    sessionStorage.setItem("initialLocation", JSON.stringify(initialLocation));
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route path={AppRouteLogin.path} element={<Login />} />
        <Route path={AppRouteResetPassword.path} element={<ResetPassword />} />
        <Route path={AppRouteLogout.path} element={<Logout />} />
        <Route path="/" element={<UserRouterProvider />}>
          <Route
            {...AppRouteHome}
            element={
              <GroupsContextProvider>
                <Home />
              </GroupsContextProvider>
            }
          />
          <Route
            {...AppRouteAllPersonas}
            element={
              <GroupsContextProvider>
                <AllPersonas />
              </GroupsContextProvider>
            }
          />
          {/*ADMIN ROUTES*/}
          <Route element={<AdminRouter />}>
            <Route {...AppRouteAdmin} element={<Admin />} />
            <Route {...AdminRouteLicenses} element={<LicenseDetails />} />
            <Route {...AdminRouteServices} element={<ServicesCatalog />} />
            <Route
              {...AdminRouteClusters}
              element={
                <GroupsContextProvider>
                  <Clusters />
                </GroupsContextProvider>
              }
            />
            <Route
              {...AdminRouteClusterGroups}
              element={
                <GroupsContextProvider>
                  <ClusterGroups />
                </GroupsContextProvider>
              }
            />
            <Route {...AdminRouteUserManagement} element={<UserManagement />} />
            <Route
              {...AdminRouteEditUser}
              element={
                <GroupsContextProvider>
                  <UserEdit />
                </GroupsContextProvider>
              }
            />
            <Route
              {...AdminRouteCreateUser}
              element={
                <GroupsContextProvider>
                  <CreateUser />
                </GroupsContextProvider>
              }
            />
          </Route>
          <Route {...AppRouteAbout} element={<AboutUs />} />
          {/*PERSONA ROUTES*/}
          <Route
            {...PersonaCreateRoute}
            element={
              <GroupsContextProvider>
                <Create />
              </GroupsContextProvider>
            }
          />
          <Route
            {...PersonaSearchRoute}
            element={
              <GroupsContextProvider>
                <Search />
              </GroupsContextProvider>
            }
          />
          <Route
            {...PersonaViewRoute}
            element={
              <PersonaContextProvider>
                <View />
              </PersonaContextProvider>
            }
          />
          <Route
            {...PersonaViewDetailRoute}
            element={
              <PersonaContextProvider>
                <ViewDetail />
              </PersonaContextProvider>
            }
          />
          <Route
            {...PersonaReviewRoute}
            element={
              <PersonaContextProvider>
                <Qa />
              </PersonaContextProvider>
            }
          />

          <Route
            {...PersonaEditRoute}
            element={
              <GroupsContextProvider>
                <PersonaContextProvider>
                  <Edit />
                </PersonaContextProvider>
              </GroupsContextProvider>
            }
          />
          <Route
            {...PersonaContentRoute}
            element={
              <PersonaContextProvider>
                <Posts />
              </PersonaContextProvider>
            }
          />
          <Route
            {...PersonaLifeEventsRoute}
            element={
              <PersonaContextProvider>
                <LifeEvents />
              </PersonaContextProvider>
            }
          />
          <Route
            {...PersonaLifeEventCreateEditRoute}
            element={
              <PersonaContextProvider>
                <LifeEventEdit />
              </PersonaContextProvider>
            }
          />

          <Route
            {...PersonaServiceIntegrationsRoute}
            element={
              <PersonaContextProvider>
                <ServiceConnections />
              </PersonaContextProvider>
            }
          />

          <Route
            {...PersonaServiceIntegrationsLogsRoute}
            element={<ServiceConnectionsLogs />}
          />

          <Route
            {...PersonaLogsRoute}
            element={
              <PersonaContextProvider>
                <PersonaLogs />
              </PersonaContextProvider>
            }
          />
          <Route
            {...PersonaServiceIntegrationsCreateEditRoute}
            element={
              <PersonaContextProvider>
                <CreateEditConnections />
              </PersonaContextProvider>
            }
          />
        </Route>
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </Suspense>
  );
}) as React.FunctionComponent;

const UserRouterProvider: React.FunctionComponent = () => {
  const { user } = useContext(UserContext);
  const isLoggedIn = user?.id && user?.username;

  if (!isLoggedIn) {
    return <Navigate to={AppRouteLogin.path!} />;
  }
  return <Outlet />;
};
