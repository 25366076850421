export enum PermissionVerbs {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
  approve = "approve",
  expunge = "expunge",
  revoke = "revoke",
  deactivate = "deactivate",
}

export enum PermissionTargets {
  persona = "persona",
  post = "post",
  realm = "realm",
  user = "user",
  namespace = "namespace",
  role = "role",
  cluster = "cluster",
  clusterGroup = "clusterGroup",
  clusterGroupUser = "clusterGroupUser",
  clusterAdmin = "clusterAdmin",
}

export const securityNamespace = "nexus-security";
