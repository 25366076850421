import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  AppRouteAbout,
  AppRouteAdmin,
  AppRouteAllPersonas,
  AppRouteLogout,
  AppRouteQueues,
} from "../../../AppRoutes";
import { PersonaSearchRoute } from "../../Persona/Routes";
import { appName } from "../../../constants";
import UserContext from "../../../contexts/UserContext";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from "@mui/icons-material/People";
import QueueIcon from "@mui/icons-material/Queue";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";

import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import nexusLogo from "../../../assets/nexus.svg";
import AppBarLogo from "@torch-ai-internal/react-display-components/lib/components/App/AppBarLogo/AppBarLogo";
import AppBarDisplay from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/AppBar";
import AppBarBrandName from "@torch-ai-internal/react-display-components/lib/components/App/AppBarBrandName/AppBarBrandName";
import imageBrandName from "../../../assets/app-bar-brand-name.png";
import { useUtilityStyles } from "../../../utils/useUtilityStyles";
import AppBarNavLink from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/components/AppBarNavLink/AppBarNavLink";
import { makeStyles } from "tss-react/mui";
import { spacings } from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/AppBarLeft";
import {
  isAdmin,
  isUserAdmin,
} from "../../Admin/components/UserManagement/utils";
import Help from "../../Help/Help";

const AppBar: React.FunctionComponent = () => {
  const { user } = useContext(UserContext);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  return (
    <>
      {user && (
        <div>
          <AppBarDisplay
            appName={appName}
            user={{
              name: user.username || "Guest",
            }}
            urls={{
              logout: AppRouteLogout.path,
            }}
            logo={<AppBarLogo src={nexusLogo} />}
            brandName={<AppBarBrandName src={imageBrandName} />}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            primaryContent={
              <MainAppBarButtons
                isHelpOpen={isHelpOpen}
                setIsHelpOpen={setIsHelpOpen}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            }
            secondaryContent={
              <SecondaryAppBarButtons setIsDrawerOpen={setIsDrawerOpen} />
            }
          />
          <Help isOpen={isHelpOpen} setIsOpen={setIsHelpOpen} />
        </div>
      )}
    </>
  );
};
export default AppBar;

const SecondaryAppBarButtons: React.FunctionComponent<{
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setIsDrawerOpen }) => {
  const { classes: utilityClasses } = useUtilityStyles();
  const theme = useTheme();

  const defaultIconProps: Partial<SvgIconProps> = {
    style: {
      color: theme.palette.common.white,
    },
  };
  return (
    <Grid container item alignItems={"flex-end"}>
      <List
        className={utilityClasses.width100}
        onClick={() => setIsDrawerOpen(false)}
      >
        <AppBarNavLink
          name={AppRouteAdmin.title}
          text={AppRouteAdmin.title}
          to={AppRouteAdmin.path}
          icon={
            <Tooltip title={AppRouteAdmin.title}>
              <SettingsIcon {...defaultIconProps} />
            </Tooltip>
          }
        />
        <AppBarNavLink
          name={AppRouteAbout.title}
          text={AppRouteAbout.title}
          to={AppRouteAbout.path}
          icon={
            <Tooltip title={AppRouteAbout.title}>
              <InfoIcon {...defaultIconProps} />
            </Tooltip>
          }
        />
      </List>
    </Grid>
  );
};

interface MainButtonsProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<MainButtonsProps["isDrawerOpen"]>>;
  isHelpOpen: boolean;
  setIsHelpOpen: Dispatch<SetStateAction<MainButtonsProps["isHelpOpen"]>>;
}
const MainAppBarButtons: React.FunctionComponent<MainButtonsProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isHelpOpen,
  setIsHelpOpen,
}) => {
  const theme = useTheme();
  const { user } = useContext(UserContext);

  const defaultIconProps: Partial<SvgIconProps> = {
    style: {
      color: theme.palette.common.white,
    },
  };
  const { classes, cx } = useAppStyles();
  const { classes: utilityClasses } = useUtilityStyles();

  const isDisabled = isUserAdmin(user?.roles) && !isAdmin(user?.roles);

  return (
    <Grid
      container
      direction={"column"}
      justifyContent="flex-start"
      className={cx(classes.sideNavGridContainer, utilityClasses.height100)}
      onClick={() => setIsDrawerOpen(false)}
    >
      <Grid item className={utilityClasses.flexGrow}>
        <Grid
          direction={"column"}
          container
          justifyContent="space-between"
          className={utilityClasses.height100}
        >
          <Grid item>
            <List>
              <AppBarNavLink
                name={PersonaSearchRoute.title}
                text={PersonaSearchRoute.title}
                to={PersonaSearchRoute.path}
                isDisabled={isDisabled}
                icon={
                  <Tooltip title={PersonaSearchRoute.title}>
                    <SearchIcon {...defaultIconProps} />
                  </Tooltip>
                }
              />
              <AppBarNavLink
                name={AppRouteAllPersonas.title}
                text={AppRouteAllPersonas.title}
                to={AppRouteAllPersonas.path}
                isDisabled={isDisabled}
                icon={
                  <Tooltip title={AppRouteAllPersonas.title}>
                    <PeopleIcon {...defaultIconProps} />
                  </Tooltip>
                }
              />
              <AppBarNavLink
                name={AppRouteQueues.title}
                text={AppRouteQueues.title}
                to={AppRouteQueues.path}
                icon={
                  <Tooltip title={AppRouteQueues.title}>
                    <QueueIcon {...defaultIconProps} />
                  </Tooltip>
                }
                isDisabled={true}
              />
              <ListItem
                className={cx(
                  classes.sideNavListItems,
                  utilityClasses.secondaryColorContrastText
                )}
                onClick={() => setIsHelpOpen(!isHelpOpen)}
                button
                key={"Help"}
              >
                {isDrawerOpen ? (
                  <ListItemIcon>
                    <HelpIcon {...defaultIconProps} />
                  </ListItemIcon>
                ) : (
                  <Tooltip title={"Help"}>
                    <ListItemIcon>
                      <HelpIcon {...defaultIconProps} />
                    </ListItemIcon>
                  </Tooltip>
                )}
                <ListItemText>{"Help"}</ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useAppStyles = makeStyles({ name: "AppStyles" })((theme) => ({
  sideNavGridContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
  },
  sideNavListItems: {
    width: spacings.appBarWidth,
  },
}));
