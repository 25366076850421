import React from "react";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { Slide } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const SnackbarContext = React.createContext(
  // This is definitional only. The provider creates the real values
  {}
);
SnackbarContext.displayName = "SnackbarContext";

const snackbarTimeout = 6000;

interface Props extends SnackbarProviderProps {}
export const Provider: React.FunctionComponent<Props> = ({
  children,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      classes={{
        variantSuccess: classes.success,
        variantWarning: classes.warning,
        variantError: classes.error,
        variantInfo: classes.secondary,
      }}
      // @ts-ignore
      TransitionComponent={Slide}
      autoHideDuration={snackbarTimeout}
      {...props}
    >
      {children}
    </SnackbarProvider>
  );
};

// We tried, there's no other way to override this package's colors to match.
const useStyles = makeStyles()((theme) => ({
  success: {
    backgroundColor: theme.palette.success.dark,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
}));
