import React from "react";

const PersonaSearchHelp: React.FunctionComponent = () => (
  <>
    <p>
      The Search queries page provides search options and displays the search
      results sorted by the Name column. The queries with the longest time since
      last activity display first.
    </p>
    <p>
      To perform a quick search, enter a name (or portion of a name) in the Name
      field and press ENTER. The page updates with the search results.
    </p>
    <p>
      To perform an advanced search, click Advanced and enter your search
      criteria using any combination of the available options on the Advanced
      search dialog:
    </p>
    <ul>
      <li>
        <b>Name</b>. Enter a name (or portion of a name).
      </li>
      <li>
        <b>CE number.</b> Enter a CE number. As you type, Nexus Q will provide
        suggestions.
      </li>
      <li>
        <b>Status</b>. Select the status of the query:
        <ul>
          <li>
            <b>Generated</b>: Generated and awaiting review
          </li>
          <li>
            <b>Rejected</b>: Generated query failed to pass review
          </li>
          <li>
            <b>Activated</b>: Generated query was released for creation in the
            real social media environment
          </li>
          <li>
            <b>Live</b>: Generated query was created in the real social media
            environment
          </li>
          <li>
            <b>Deactivated</b>: Generated query has reached end-of-life
          </li>
          <li>
            <b>Uncovered</b>: The query was detected as not belonging to a real
            person
          </li>
        </ul>
      </li>
      <li>
        To execute the search, press<code>ENTER</code>. The Dashboard updates
        and displays a list of queries that match the search criteria.
      </li>
      <li>
        If you enter both a CE number and a name. Nexus Q will return results
        that match either criteria (i.e., a logical OR function).
      </li>
      <li>
        If the search results do not display as expected, be sure that only one
        of the search fields,<b>CE number</b> or <b>Name search</b>, contains
        text. Clear out the field that you are not using.
      </li>
    </ul>
    <p>
      Each row includes a query's profile photo (from their last updated social
      media integration settings), query name, CE ID, and Last activity
      indicator. The <code>Last activity</code> column indicates the last time
      that a post was approved for the query:
    </p>
    <ul>
      <li>Green dot. The last post was less than seven days ago.</li>
      <li>Orange dot. The last post was 7 to 14 days ago.</li>
      <li>
        Red dot. The last post was 15+ days ago or there was no activity found.
      </li>
    </ul>
    <p>
      Each row also includes icons that enable you to perform the following
      tasks for the query:
    </p>
    <ul>
      <li>Create a post</li>
      <li>Review and approve posts</li>
      <li>Manage social media integration settings</li>
    </ul>
  </>
);

export default PersonaSearchHelp;
