export enum UserType {
  SuperAdmin = "SuperAdministrator",
  UserAdmin = "UserAdministrator",
  Admin = "Administrator",
  Operator = "User",
  JrOperator = "JrUser",
}

export const userStatus = {
  account: {
    expiringSoon: "Account expiring soon",
    expired: "Account expired",
  },
  password: {
    expiringSoon: "Password expiring soon",
    expired: "Password expired",
  },
  disabled: "Disabled",
};
