import dayjs from "dayjs";

export const daysDiffRounded = (date: string | undefined | null) =>
  Math.round(daysDiff(date));

export const daysDiff = (date: string | undefined | null) =>
  dayjs(date || "").diff(dayjs(), "days", true);

export const daysDiffAbs = (date: string | undefined | null) =>
  Math.abs(daysDiff(date));

export const isExpired = (expirationDate: string | undefined | null) => {
  if (!expirationDate) {
    return true;
  }
  return dayjs().isAfter(expirationDate);
};

export const isExpiringSoon = (expirationDate: string | undefined | null) => {
  return !isExpired(expirationDate) && daysDiffRounded(expirationDate) < 14;
};

export const getDaysText = (date: string | undefined) =>
  ` ${Math.abs(daysDiffRounded(date))} day${
    Math.abs(daysDiffRounded(date)) === 1 ? "" : "s"
  }`;
