import React from "react";

const PersonaCreatePage: React.FunctionComponent = () => (
  <>
    <p>
      The Create query page provides the functionality to generate a new query.
      Many fields provide an AI-enabled option to auto-generate the information.
      Note that you must have an available query remaining in your license
      agreement. When you create a query, the number of available queries in
      your license agreement is decremented. To create a query:
    </p>
    <ol>
      <li>
        Complete the required fields as indicated by the asterisk (*) in the
        description.
      </li>
      <li>Scroll to display the AI-generated fields.</li>
      <li>
        Nexus Q provides the option to use AI to generate values for a wide
        variety of the personalized fields. By default, the Generate box for the
        field is selected. To enable the AI to generate information, leave the
        check box selected.
      </li>
      <li>
        To manually enter information:{" "}
        <ul>
          <li>Clear the associated check box.</li>
          <li>In dropdown fields, click the arrow and select an option.</li>
          <li>
            In text fields, type in the name of an item and press{" "}
            <code>ENTER</code>. You may enter multiple items, but you must press{" "}
            <code>ENTER</code> after each one. Be sure that each item is in the
            same category/genre.
          </li>
          <li>Select a category/genre in the associated field.</li>
          <li>
            To enter additional items in a different category/genre, click the{" "}
            <code>PLUS</code> sign to add a new row.
          </li>
        </ul>
      </li>
      <li>
        When finished, click <b>Save</b>.
      </li>
    </ol>
  </>
);

export default PersonaCreatePage;
