import { AppRoutePersonas, AppRouteProps } from "../../AppRoutes";

export const PersonaViewRoute: AppRouteProps = {
  title: "View query",
  path: `${AppRoutePersonas.path}/:id`,
};

export const PersonaViewDetailRoute: AppRouteProps = {
  title: "View detail query",
  path: `${AppRoutePersonas.path}/:id/detail-query`,
};

export const PersonaLifeEventsRoute: AppRouteProps = {
  title: "Life events",
  path: `${AppRoutePersonas.path}/:id/life-events`,
};

export const PersonaContentRoute: AppRouteProps = {
  title: "Posts",
  path: `${AppRoutePersonas.path}/:id/content`,
};

export interface PersonaServiceIntegrationsRouteParams {
  id: string;
}

export const PersonaServiceIntegrationsRoute: AppRouteProps = {
  title: "Social media integrations",
  path: `${AppRoutePersonas.path}/:id/service-integrations`,
};

export interface PersonaServiceIntegrationsCreateEditRouteParams
  extends PersonaServiceIntegrationsRouteParams {
  connectionId?: string;
}

export const PersonaServiceIntegrationsCreateEditRoute: AppRouteProps = {
  title: "Social media integration",
  path: `${AppRoutePersonas.path}/:id/service-integrations/:connectionId`,
};

export const PersonaLifeEventCreateEditRoute: AppRouteProps = {
  title: "Life event",
  path: `${AppRoutePersonas.path}/:id/life-event/:lifeEventId`,
};

export interface PersonaServiceIntegrationsLogsRouteParams {
  id: string;
  connectionId?: string;
}

export const PersonaServiceIntegrationsLogsRoute: AppRouteProps = {
  title: "Service connections logs",
  path: `${AppRoutePersonas.path}/:id/service-integrations/:connectionId/logs`,
};

export interface PersonaLogsRouteParams {
  id: string;
}

export const PersonaLogsRoute: AppRouteProps = {
  title: "Logs",
  path: `${AppRoutePersonas.path}/:id/logs`,
};

export interface PersonaEditRouteParams {
  key: string;
}

export const PersonaReviewRoute: AppRouteProps = {
  title: "Review",
  path: `${AppRoutePersonas.path}/:id/review`,
};

export const PersonaEditRoute: AppRouteProps = {
  title: "Edit query",
  path: `${AppRoutePersonas.path}/:id/edit`,
};

export const PersonaCreateRoute: AppRouteProps = {
  title: "Create query",
  path: `${AppRoutePersonas.path}/create`,
};

export const PersonaSearchRoute: AppRouteProps = {
  title: "Search queries",
  path: `${AppRoutePersonas.path}/search`,
};
