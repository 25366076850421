import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { sessionCacheTokenPath } from "../contexts/UserContext";

// Helper functions
const withToken = setContext(async () => {
  const authorization = sessionStorage.getItem(sessionCacheTokenPath);
  return { authorization };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const { authorization } = operation.getContext();
  operation.setContext(() => ({
    headers: {
      Authorization: authorization,
    },
  }));
  return forward(operation);
});

// Create the API clients
const securityHttpLink = createHttpLink({
  // You should use an absolute URL here
  uri:
    // @ts-expect-error Doesn't like window direct access
    window.env.REACT_APP_SECURITY_GRAPHQL_ENDPOINT ||
    process.env.REACT_APP_SECURITY_GRAPHQL_ENDPOINT,
});
export const securityClient = new ApolloClient({
  link: ApolloLink.from([securityHttpLink]),
  cache: new InMemoryCache(),
});

const adminHttpLink = createHttpLink({
  // You should use an absolute URL here
  uri:
    // @ts-expect-error Doesn't like window direct access
    window.env.REACT_APP_ADMIN_GRAPHQL_ENDPOINT ||
    process.env.REACT_APP_ADMIN_GRAPHQL_ENDPOINT,
});
export const adminClient = new ApolloClient({
  link: ApolloLink.from([withToken, authMiddleware.concat(adminHttpLink)]),
  cache: new InMemoryCache(),
});

const syntheticsHttpLink = createHttpLink({
  // You should use an absolute URL here
  uri:
    // @ts-expect-error Doesn't like window direct access
    window.env.REACT_APP_SYNTHETICS_GRAPHQL_ENDPOINT ||
    process.env.REACT_APP_SYNTHETICS_GRAPHQL_ENDPOINT,
});

export const syntheticsClient = new ApolloClient({
  link: ApolloLink.from([withToken, authMiddleware.concat(syntheticsHttpLink)]),
  cache: new InMemoryCache(),
});
