import React from "react";
import {
  GetLicenseDetailsQueryHookResult,
  useGetLicenseDetailsQuery,
} from "../generated/torch-universe/synthetics.types";

interface Context {
  licenseQuery: GetLicenseDetailsQueryHookResult;
}
export const LicenseContext = React.createContext<Context>({} as Context);
LicenseContext.displayName = "LicenseContext";

export const Provider: React.FunctionComponent = ({ children }) => {
  const getLicenseDetailsQuery = useGetLicenseDetailsQuery();

  return (
    <LicenseContext.Provider value={{ licenseQuery: getLicenseDetailsQuery }}>
      {children}
    </LicenseContext.Provider>
  );
};
