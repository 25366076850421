import React from "react";

const PersonaOverviewHelp: React.FunctionComponent = () => (
  <>
    <p>
      The Social media integrations page provides a centralized location to
      store the social media configuration details, such as user name, password,
      profile photo, etc., for each query.
    </p>
    <p>
      This page displays all current integrations. You may edit or delete
      existing entries.{" "}
    </p>
    <p>To create a new integration: </p>
    <ol>
      <li>
        Click <b>New</b>. The details page displays.{" "}
      </li>
      <li>
        Click in the <b>Select platform</b> field and select <b>facebook</b>.
      </li>
      <li>
        Complete the following fields:
        <ul>
          <li>Username</li>
          <li>Password</li>
          <li>Display name</li>
        </ul>
      </li>
      <li>
        Click <b>Select Profile Image</b>. The Select profile photo dialog
        displays with several options. If an image is being used by another
        query, a number displays indicating the number of times it is being
        used.
      </li>
      <li>
        Select a profile image and click <b>Save</b>.
      </li>
      <li>
        Click in the <b>Email</b> field and select the email address to use for
        recovering this account.
      </li>
      <li>
        Click in the <b>Phone</b> field and select the phone number to use for
        recovering this account.
      </li>
      <li>
        Click <b>Create</b>.
      </li>
    </ol>
  </>
);

export default PersonaOverviewHelp;
