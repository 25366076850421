import {
  BookGenre,
  EducationLevel,
  Ethnicity,
  Gender,
  InterestedIn,
  LifeEvent,
  MaritalStatus,
  Maybe,
  MovieGenre,
  MusicalGenre,
  PersonLifecycleState,
  Sport,
  TelevisionGenre,
} from "../../generated/torch-universe/synthetics.types";

// TODO someday this should instead be an API call so we can get labels + enum values, but good enough for now
export const getDisplayName = <T = string>(
  value: Maybe<T> | undefined,
  list: PersonaOption<T>[]
) => {
  if (!value) {
    return "";
  }
  const index = list.findIndex((obj) => value === obj.key);
  return list[index].displayName;
};

export const isPersonaDeactivated = (
  state: PersonLifecycleState | undefined | null
) => {
  return state === PersonLifecycleState.Deactivated;
};

export interface PersonaOption<T> {
  key: T;
  displayName: string;
}

export const genders: PersonaOption<Gender>[] = [
  { key: Gender.Female, displayName: "Female" },
  { key: Gender.Male, displayName: "Male" },
  { key: Gender.NonBinary, displayName: "Non-binary" },
];
export const maritalStatuses: { key: MaritalStatus; displayName: string }[] = [
  { key: MaritalStatus.Divorced, displayName: "Divorced" },
  { key: MaritalStatus.Married, displayName: "Married" },
  { key: MaritalStatus.Single, displayName: "Single" },
  { key: MaritalStatus.Widowed, displayName: "Widowed" },
];
export type InterestedInOption = {
  key: InterestedIn.Men | InterestedIn.Women | InterestedIn.MenAndWomen;
  displayName: string;
};
export const interestedInOptions: InterestedInOption[] = [
  { key: InterestedIn.Men, displayName: "Men" },
  { key: InterestedIn.Women, displayName: "Women" },
  { key: InterestedIn.MenAndWomen, displayName: "Men and Women" },
];

export const ethnicities: PersonaOption<Ethnicity>[] = [
  { key: Ethnicity.Asian, displayName: "Asian" },
  { key: Ethnicity.Black, displayName: "Black" },
  { key: Ethnicity.Latinx, displayName: "Latinx" },
  { key: Ethnicity.White, displayName: "White" },
];
export const educationLevels: PersonaOption<EducationLevel>[] = [
  { key: EducationLevel.Incomplete, displayName: "Incomplete" },
  { key: EducationLevel.HighSchool, displayName: "High school" },
  { key: EducationLevel.SomeCollege, displayName: "Some college" },
  { key: EducationLevel.Associates, displayName: "Associates" },
  { key: EducationLevel.Bachelors, displayName: "Bachelors" },
  { key: EducationLevel.Masters, displayName: "Masters" },
  { key: EducationLevel.Doctorate, displayName: "Doctorate" },
  { key: EducationLevel.Law, displayName: "Law" },
  { key: EducationLevel.Medical, displayName: "Medical" },
];

export const musicGenreOptions: PersonaOption<MusicalGenre>[] = [
  { key: MusicalGenre.Jazz, displayName: "Jazz" },
  { key: MusicalGenre.Randb, displayName: "R&B" },
  { key: MusicalGenre.Classical, displayName: "Classical" },
  { key: MusicalGenre.Rock, displayName: "Rock" },
  { key: MusicalGenre.Rap, displayName: "Rap" },
  { key: MusicalGenre.Blues, displayName: "Blues" },
  { key: MusicalGenre.Country, displayName: "Country" },
  { key: MusicalGenre.Metal, displayName: "Metal" },
];

export const movieGenreOptions: PersonaOption<MovieGenre>[] = [
  { key: MovieGenre.Drama, displayName: "Drama" },
  { key: MovieGenre.Action, displayName: "Action" },
  { key: MovieGenre.Thriller, displayName: "Thriller" },
  { key: MovieGenre.Mystery, displayName: "Mystery" },
  { key: MovieGenre.Comedy, displayName: "Comedy" },
  { key: MovieGenre.Crime, displayName: "Crime" },
  { key: MovieGenre.Romance, displayName: "Romance" },
  { key: MovieGenre.Scifi, displayName: "Sci-Fi" },
];
export const televisionGenreOptions: PersonaOption<TelevisionGenre>[] = [
  { key: TelevisionGenre.Drama, displayName: "Drama" },
  { key: TelevisionGenre.Action, displayName: "Action" },
  { key: TelevisionGenre.Thriller, displayName: "Thriller" },
  { key: TelevisionGenre.Mystery, displayName: "Mystery" },
  { key: TelevisionGenre.Comedy, displayName: "Comedy" },
  { key: TelevisionGenre.Crime, displayName: "Crime" },
  { key: TelevisionGenre.Romance, displayName: "Romance" },
  { key: TelevisionGenre.Scifi, displayName: "Sci-Fi" },
];

export const bookGenreOptions: PersonaOption<BookGenre>[] = [
  { key: BookGenre.Romance, displayName: "Romance" },
  { key: BookGenre.Mystery, displayName: "Mystery" },
  { key: BookGenre.Inspirational, displayName: "Inspirationals" },
  { key: BookGenre.Scifi, displayName: "Sci-Fi" },
  { key: BookGenre.Horror, displayName: "Horror" },
];

export const sportsOptions: PersonaOption<Sport>[] = [
  { key: Sport.Boxing, displayName: "Boxing" },
  { key: Sport.Golf, displayName: "Golf" },
  { key: Sport.Hockey, displayName: "Hockey" },
  { key: Sport.Mlb, displayName: "MLB" },
  { key: Sport.Nfl, displayName: "NFL" },
  { key: Sport.Nascar, displayName: "Nascar" },
  { key: Sport.Nba, displayName: "NBA" },
  { key: Sport.Soccer, displayName: "Soccer" },
  { key: Sport.Tennis, displayName: "Tennis" },
];

export const personaLifecycleStates: PersonaOption<PersonLifecycleState>[] = [
  { key: PersonLifecycleState.Live, displayName: "Live" },
  { key: PersonLifecycleState.Generated, displayName: "Generated" },
  { key: PersonLifecycleState.Activated, displayName: "Activated" },
  { key: PersonLifecycleState.Deactivated, displayName: "Deactivated" },
  { key: PersonLifecycleState.Uncovered, displayName: "Uncovered" },
  { key: PersonLifecycleState.Rejected, displayName: "Rejected" },
];

export const lifeEventOptions: PersonaOption<LifeEvent>[] = [
  {
    displayName: "Childbirth",
    key: LifeEvent.Childbirth,
  },
  {
    displayName: "Job",
    key: LifeEvent.Job,
  },
  {
    displayName: "Marriage",
    key: LifeEvent.Marriage,
  },
  {
    displayName: "Death",
    key: LifeEvent.Death,
  },
];
