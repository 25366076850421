import React from "react";
import { Helmet } from "react-helmet-async";
import Providers from "./components/Providers";
import { getMetaTitle } from "@torch-ai-internal/react-display-components/lib/utils/meta";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import Router from "./components/Router";
import AppBar from "./components/AppBar";
import "./App.css";
import { useAppStyles } from "@torch-ai-internal/react-display-components/lib/components/App/styles";
import { makeStyles } from "tss-react/mui";

const App: React.FunctionComponent = () => {
  const { classes: rdcClasses } = useAppStyles();
  const { classes, cx } = useStyles();

  return (
    <Providers>
      <Helmet>
        <title>{getMetaTitle()}</title>
      </Helmet>
      <div className={rdcClasses.root}>
        <AppBar />
        <main className={cx(rdcClasses.main, classes.main)}>
          <ErrorBoundary>
            <Router />
          </ErrorBoundary>
        </main>
      </div>
    </Providers>
  );
};
export default App;

const useStyles = makeStyles()((theme) => ({
  main: {
    "&>.MuiContainer-root": {
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    flexDirection: "column",
  },
}));
